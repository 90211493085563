import React, { useState } from 'react';
import './App.css';
import styled from "styled-components";
import axios from "axios";
import useWebSocket, { ReadyState } from "react-use-websocket";

const StyledInputMSISDN = styled.input`
  display: flex;
  margin:  auto;
  font-size: 12px; 
  width: 50%;
  padding: 8px;
  margin-bottom: 4px;
  align-self: center; 
  border: 1px solid lightblue;
`;
const StyledInputMessage = styled.textarea`
  display: flex;
  margin: auto;
  font-size: 12px; 
  width: 100%;
  rows: 10;
  padding: 8px; 
  min-height: 180px;
  border: 1px solid lightblue;
`;
const Button = styled.button`
  background-color: #008000;
  color: white;
  padding: 8px 15px;
  border-radius: 5px;
  width: 20%;
  outline: 0;
  border: 0; 
  text-transform: uppercase;
  margin: 10px 0px;
  cursor: pointer;
  font-size: 18px; 
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
  &:hover {
    background-color:  #255736;
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

const ButtonToggle = styled(Button)`
  opacity: 0.2;
  ${({ active }) =>
    active &&
    `
    opacity: 1; 
  `}
`;

function useSmsInput(defaultValue = "") {
  const [value, setValue] = useState(defaultValue);
  function onChange(e) {
    setValue(e.target.value);
  }
  function reset(e) {
    setValue('');
  }
  return {
    value,
    onChange,
    reset
  };
}

function useToInput(defaultValue = "") {
  const [value, setValue] = useState(defaultValue);
  function onChange(e) {
    setValue(e.target.value);
  }
  function reset(e) {
    setValue('');
  }
  return {
    value,
    onChange,
    reset
  };
}

function useFromInput(defaultValue = "") {
  const [value, setValue] = useState(defaultValue);
  function onChange(e) {
    setValue(e.target.value);
  }
  function reset(e) {
    setValue('');

  }
  return {
    value,
    onChange,
    reset
  };
}

function App() {
  const [messages, setMessages] = useState([]);
  const  { reset: resetSms, ...smsProps } = useSmsInput();
  const { reset: resetTo, ...toProps }= useToInput();
  const { reset: resetFrom, ...fromProps } = useFromInput();
  const [url, setUrl] = useState();
  const WS_URL = "wss://10.1.30.130:3001";
  const { sendJsonMessage, readyState } = useWebSocket(WS_URL, {
    onOpen: () => {
      console.log("WebSocket connection established.");
    },
    onMessage: (message) => {
      console.log("Istrusted" + message.isTrusted);
      console.log("Istrusted" + message.data);
       const data = JSON.parse(message.data);

       console.log("Received message from server: " + data);
      //if (data.type === "greeting") {
        //console.log("Received greeting:", data.content);
      //}
      setMessages([...messages, data]);
    },
    share: true,
    filter: () => false,
    retryOnError: true,
    shouldReconnect: () => true,
  });

  /*useEffect(() => {
    if (readyState === ReadyState.OPEN) {
  
      sendJsonMessage ({
        from: fromProps.value,
        to: toProps.value,
        text: smsProps.value,
        timestamp: new Date().toISOString(),
      });
    }
  }, [sendJsonMessage, readyState]);*/


  const sendMessage = () => {
    var url = "http://10.1.30.60?msisdn=" + fromProps.value + "&to=" +toProps.value + "&text=" + smsProps.value.urlEncoded();
    setUrl(url);
    axios.get(url).then(response => setUrl("Sent")).catch(function(error) {
      console.log(error);
    });;
    // Implement sending messages via WebSocket here
  };


  const sendMessage3 = (event) => {
    if (smsProps.value.trim() !== '') {
      const message = {
        from: fromProps.value,
        to: toProps.value,
        type: "SENT",
        text: smsProps.value,
        timestamp: new Date().toISOString(),
      };
      sendJsonMessage(message);
      resetSms();
      resetFrom();
      resetTo();
    }
  };

  return (
    <div className="App">
      <div>
        <h2> TrustCall Dev</h2>
        <div className="center">
          <StyledInputMSISDN {...fromProps} placeholder="From" />
          <StyledInputMSISDN {...toProps} placeholder="To" />
          <StyledInputMessage {...smsProps} placeholder="Message" />
          <ButtonToggle active={smsProps.value && fromProps.value && toProps.value} onClick={sendMessage3}>Send</ButtonToggle>
        </div>
      </div>
      <div>
        <h2>SMS</h2>
        <table className="table-container">
          <thead>
            <tr>
              <td>Date</td>
              <td>Type</td>
              <td>From</td>
              <td>To</td>
              <td>Message</td>
            </tr>
          </thead>
          <tbody>
          {messages.map((message, index) => (
          <tr key={index}>
            <td>{message.timestamp}</td>
            <td>{message.type}</td>
            <td>{message.from}</td>
            <td>{message.to}</td>
            <td>{message.text}</td>
          </tr>
          ))}
          </tbody>
      </table>
    </div>
      </div>
  );
}

export default App;

